<script setup lang="ts">
import type MobileGuest from '@/components/Search/Input/V2/MobileGuest.vue'

const { data, setData, goToSearch, trackingInputClick } = useSearchInput()

const guestRef = ref<InstanceType<typeof MobileGuest> | null>(null)

const modal = ref<'location' | 'guest'>()

const closeModal = () => {
  modal.value = undefined
}

const openModal = (type: 'location' | 'guest') => {
  modal.value = type

  const trakingKey = {
    location: 'destination',
    guest: 'guests',
  } as const

  trackingInputClick(trakingKey[type])
}

const handlePrimaryButton = () => {
  if (guestRef.value) setData('guest', guestRef.value.tmpGuest)
  modal.value = undefined
}

const handleLink = () => {
  if (guestRef.value) {
    guestRef.value.tmpGuest = 0
    guestRef.value.clear()
  }
}
</script>

<template>
  <div class="w-full rounded bg-white shadow-[0_0_38px_0_rgba(0,0,0,0.10)]">
    <div
      :class="[
        'relative flex flex-wrap items-center border-b py-4 pl-4 pr-10',
        { 'text-gray-500': !data.location.name },
      ]"
      @click="openModal('location')"
    >
      <BaseIcon name="pinAlt" class="mr-2" />
      <div class="w-[85%] truncate text-left">
        {{ data.location.name || $t('search.destination') }}
      </div>
      <button
        v-show="data.location.name"
        class="absolute inset-y-0 right-3"
        type="button"
        @click.stop="setData('location', {})"
      >
        <BaseIcon color="text-gray-700" name="close" />
      </button>
    </div>
    <SearchInputV2Calendar
      class="w-full border-b text-gray-500"
      @clicked="trackingInputClick('calendar')"
    />
    <div
      :class="[
        'relative flex items-center border-b py-4 pl-4 pr-10',
        { 'text-gray-500': !data.guest },
      ]"
      @click="openModal('guest')"
    >
      <BaseIcon name="group" class="mr-2" />
      {{ $t('search.capacity', { count: data.guest }) }}
      <button
        v-show="data.guest"
        class="absolute inset-y-0 right-3"
        type="button"
        @click.stop="setData('guest', 0)"
      >
        <BaseIcon color="text-gray-700" name="close" />
      </button>
    </div>
    <BaseButton
      block
      class="!rounded-t-none"
      color="secondary"
      @click="goToSearch"
    >
      {{ $t('search.search') }}
    </BaseButton>
  </div>

  <BaseModalV2
    :has-back-button="modal === 'guest'"
    :has-close-button="false"
    :link="modal === 'guest' ? $t('search.eraseAll') : undefined"
    :model-value="Boolean(modal)"
    :primary-button="modal === 'guest' ? $t('action.validate') : undefined"
    mobile-full-height
    @primary-button="handlePrimaryButton"
    @link="handleLink"
    @back="modal = undefined"
    @close="modal = undefined"
  >
    <template #content>
      <SearchInputV2MobileLocation
        v-if="modal === 'location'"
        @cancel="closeModal"
        @selected="closeModal"
      />
      <SearchInputV2MobileGuest v-if="modal === 'guest'" ref="guestRef" />
    </template>
  </BaseModalV2>
</template>
