<script setup lang="ts">
const emits = defineEmits<{ selected: []; cancel: [] }>()

const {
  data,
  fetchDestinationsAndHouses,
  goToHouse,
  keepLastSearchQuery,
  fetchTopDestination,
  resetDestinationsAndHouses,
  setData,
} = useSearchInput()

const inputSearch = computed({
  get() {
    return data.value.searchQuery
  },
  set(value) {
    setData('searchQuery', value)
  },
})

const inputSearchRef = ref<HTMLInputElement>()

onMounted(async () => {
  resetDestinationsAndHouses()
  await fetchTopDestination()

  inputSearchRef.value?.focus()
})

const handleAfterClick = () => {
  keepLastSearchQuery()

  if (data.value.location?.type === 'house') {
    return goToHouse()
  }

  emits('selected')
}

const onInput = async () => {
  await fetchDestinationsAndHouses(inputSearch.value)
}
</script>

<template>
  <div>
    <div class="mb-8 flex items-center">
      <div
        class="relative flex w-full items-center rounded border border-gray-700 bg-gray-100 py-2.5 pl-3 pr-8"
      >
        <BaseIcon name="search" class="mr-2" :size="1.2" />
        <input
          ref="inputSearchRef"
          v-model="inputSearch"
          :placeholder="$t('search.placeholder')"
          autocomplete="off"
          class="w-full bg-gray-100"
          type="text"
          @input="onInput"
        />
        <button
          v-show="inputSearch.length"
          class="absolute inset-y-0 right-2"
          type="button"
          @click="inputSearch = ''"
        >
          <BaseIcon color="text-gray-700" name="close" :size="1.1" />
        </button>
      </div>
      <button
        class="ml-2 flex-none text-xs font-semibold"
        type="button"
        @click="$emit('cancel')"
      >
        {{ $t('action.cancel') }}
      </button>
    </div>

    <SearchInputV2LocationOptions
      :search-query="inputSearch"
      @option-clicked="handleAfterClick"
    />
  </div>
</template>
