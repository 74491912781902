<script setup lang="ts">
import type Calendar from '@/components/Search/Input/V2/Calendar.vue'
import type Guest from '@/components/Search/Input/V2/Guest.vue'

const { data, goToSearch, resetData, trackingInputClick } = useSearchInput()

const calendarRef = ref<InstanceType<typeof Calendar> | null>(null)
const guestRef = ref<InstanceType<typeof Guest> | null>(null)

const nextStep = () => {
  if (!data.value.startDate && !data.value.endDate) {
    calendarRef.value?.openCalendar()
  } else if (!data.value.guest) {
    guestRef.value?.openDropdown()
  }
}

onBeforeUnmount(() => {
  resetData()
})
</script>

<template>
  <div
    class="relative flex h-[52px] w-min items-center rounded bg-white py-2 pl-5 pr-2"
  >
    <SearchInputV2Location
      @clicked="trackingInputClick('destination')"
      @selected="nextStep"
    />
    <div class="mx-4 h-1/2 border-r border-gray-300" />
    <SearchInputV2Calendar
      ref="calendarRef"
      @clicked="trackingInputClick('calendar')"
      @selected="nextStep"
    />
    <div class="mx-4 h-1/2 border-r border-gray-300" />
    <SearchInputV2Guest
      ref="guestRef"
      @clicked="trackingInputClick('guests')"
    />

    <BaseButton
      class="rounded !px-3 !py-2 !text-md"
      color="secondary"
      @click="goToSearch"
    >
      {{ $t('search.search') }}
    </BaseButton>
  </div>
</template>
