export const adjustArrays = <T>(
  { minPerArray, maxItems }: { minPerArray: number; maxItems: number },
  ...arrays: T[][]
) => {
  const arrayCounts = arrays.map((array) => array.length)

  const totalItems = arrayCounts.reduce((acc, count) => acc + count, 0)
  if (totalItems <= maxItems) {
    return arrays.map((array) => array.slice())
  }

  const adjustedArrays = arrays.map((array, index) =>
    array.slice(0, Math.min(minPerArray, arrayCounts[index])),
  )

  const usedItems = adjustedArrays.reduce((acc, array) => acc + array.length, 0)
  let remainingItems = maxItems - usedItems

  for (let i = 0; remainingItems > 0; i = (i + 1) % arrays.length) {
    const array = arrays[i]
    const currentAdjustedArray = adjustedArrays[i]

    if (currentAdjustedArray.length < array.length) {
      currentAdjustedArray.push(array[currentAdjustedArray.length])
      remainingItems--
    }
  }

  return adjustedArrays
}
