import { adjustArrays } from '@/utils/array'
import type { HouseSlug, Locale } from 'lc-services/types'

export type SearchInputState = {
  data: {
    location: {
      id?: string
      name?: string
      slug?: HouseSlug
      type?: 'destination' | 'house'
    }
    searchQuery: string
    searchQueryLast: string
    startDate: Date | null
    endDate: Date | null
    guest: number
  }
  destinations: { id: string; name: string }[]
  houses: { id: string; name: string; slug?: HouseSlug }[]
  topDestinations: { id: string; name: string }[]
}

export const useSearchInput = () => {
  const { $lcServicesSearch } = useNuxtApp()
  const { algoliaIds } = useAuth()
  const { locale, t } = useI18n<unknown, Locale>()
  const { searchController, searchPresenter } = $lcServicesSearch
  const { trackEvent } = useTracking()
  const localePath = useLocalePath()
  const route = useRoute()

  const searchInputState = useState<SearchInputState>('search-input', () => ({
    data: {
      location: {},
      startDate: null,
      searchQuery: '',
      searchQueryLast: '',
      endDate: null,
      guest: 0,
    },
    destinations: [],
    houses: [],
    topDestinations: [],
  }))

  const topDestinations = computed(() => searchInputState.value.topDestinations)
  const destinations = computed(() => searchInputState.value.destinations)
  const houses = computed(() => searchInputState.value.houses)
  const data = computed(() => searchInputState.value.data)

  const optionsSearchEmpty = computed(
    () =>
      [
        {
          title: t('search.topDestinations'),
          key: 'destination',
          icon: 'pinAlt',
          items: topDestinations.value,
        },
      ] as const,
  )

  const optionsSearch = computed(() => {
    const [newDestinations, newHouses] = adjustArrays(
      { minPerArray: 2, maxItems: 6 },
      destinations.value,
      houses.value,
    )

    return [
      ...(newDestinations.length
        ? [
            {
              title: t('search.destinations'),
              key: 'destination',
              icon: 'pinAlt',
              items: newDestinations,
            },
          ]
        : []),
      ...(newHouses.length
        ? [
            {
              title: t('search.homes'),
              key: 'house',
              icon: 'home',
              items: newHouses,
            },
          ]
        : []),
    ] as Array<{
      title: string
      key: 'destination' | 'house'
      icon: 'home' | 'pinAlt'
      items: { id: string; name: string; slug?: HouseSlug }[]
    }>
  })

  const fetchTopDestination = async () => {
    if (searchInputState.value.topDestinations.length) return

    await searchController.getTopDestinations(locale.value, algoliaIds.value)

    searchInputState.value.topDestinations =
      searchPresenter.vm.topDestinationsResult.map((d) => ({
        id: d.id,
        name: d.name,
      }))
  }

  const fetchDestinationsAndHouses = async (value: string) => {
    if (!value) resetDestinationsAndHouses()

    await searchController.searchHousesAndDestinations(
      value,
      locale.value,
      'client',
      algoliaIds.value,
    )

    searchInputState.value.destinations =
      searchPresenter.vm.destinationsResult.map((h) => ({
        id: h.id,
        name: h.name,
      }))

    searchInputState.value.houses = searchPresenter.vm.housesResult.map(
      (h) => ({
        id: h.id,
        name: h.name,
        slug: h.slug,
      }),
    )
  }

  const goToHouse = () => {
    trackingGoTo('house')

    navigateTo(
      localePath({
        name: 'house-slug',
        params: { slug: data.value.location?.slug?.[locale.value] },
      }),
    )
  }

  const goToSearch = () => {
    trackingGoTo('destination')

    navigateTo(
      localePath({
        name: 'search',
        query: {
          ...(data.value.location.id
            ? { destinationId: data.value.location.id }
            : {}),
          ...(data.value.guest
            ? { numericFilters: `capacity >= ${data.value.guest}` }
            : {}),
          ...(data.value.startDate && data.value.endDate
            ? {
                startDate: $dayjs(data.value.startDate).format('YYYY-MM-DD'),
                endDate: $dayjs(data.value.endDate).format('YYYY-MM-DD'),
              }
            : {}),
        },
      }),
    )
  }

  const trackingGoTo = (type: 'destination' | 'house') => {
    const searchQuery = data.value.searchQueryLast
    const suggestion = searchQuery.length
      ? type === 'destination'
        ? 'location'
        : 'house'
      : 'default'
    const pageType = route.meta?.gtmAdditionalEventData?.page_type || 'other'

    const filterAppliedName = [
      data.value.location.id && 'desti_id',
      data.value.startDate && data.value.endDate && 'dates_selected',
      data.value.guest && 'capacity',
    ].filter(Boolean)

    trackEvent({
      event: 'search_started',
      filter_applied_name: filterAppliedName,
      source: pageType,
      page_type: pageType,
      suggestion,
      ...(searchQuery.length
        ? { search_query: searchQuery, is_autocomplete: Boolean(searchQuery) }
        : {}),
    })
  }

  const trackingInputClick = (field: 'destination' | 'calendar' | 'guests') => {
    const pageType = route.meta?.gtmAdditionalEventData?.page_type || 'other'

    trackEvent({
      event: 'search_input_clicked',
      field,
      page_type: pageType,
      source: pageType,
    })
  }

  const keepLastSearchQuery = () => {
    setData('searchQueryLast', data.value.searchQuery)
    setData('searchQuery', '')
  }

  const resetDestinationsAndHouses = () => {
    searchInputState.value.destinations = []
    searchInputState.value.houses = []
  }

  const setData = <K extends keyof SearchInputState['data']>(
    key: K,
    value: SearchInputState['data'][K],
  ) => {
    searchInputState.value.data[key] = value
  }

  const resetData = () => {
    searchInputState.value.data = {
      location: {},
      startDate: null,
      searchQuery: '',
      searchQueryLast: '',
      endDate: null,
      guest: 0,
    }
  }

  return {
    data,
    destinations,
    fetchDestinationsAndHouses,
    fetchTopDestination,
    goToHouse,
    goToSearch,
    houses,
    keepLastSearchQuery,
    optionsSearch,
    optionsSearchEmpty,
    resetDestinationsAndHouses,
    setData,
    resetData,
    topDestinations,
    trackingInputClick,
  }
}
