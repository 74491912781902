<script setup lang="ts">
import type BaseInputNumber from '@/components/BaseForm/components/BaseInputNumber.vue'

const { data } = useSearchInput()

const groupRef = ref<InstanceType<typeof BaseInputNumber> | null>(null)
const tmpGuest = ref(data.value.guest)

const clear = () => {
  if (groupRef.value) groupRef.value.inputValue = 0
  tmpGuest.value = 0
}

defineExpose({
  tmpGuest,
  clear,
})
</script>

<template>
  <div>
    <div class="mb-4 text-center font-bold">
      {{ $t('search.groupComposition') }}
    </div>

    <div class="flex items-center justify-between py-4">
      <div class="flex">
        <BaseIcon color="text-gray-700" class="mr-3" name="group" />
        <p class="mb-0">
          {{ $t('search.guests_mobile') }}
        </p>
      </div>
      <div class="flex w-32 items-center">
        <BaseFormComponentsBaseInputNumber
          ref="groupRef"
          v-model="tmpGuest"
          name="group"
          wrapper-class="w-full"
        />
      </div>
    </div>
  </div>
</template>
