<script setup lang="ts">
const emits = defineEmits<{ selected: []; clicked: [] }>()

const { t } = useI18n()

const {
  data,
  destinations,
  fetchDestinationsAndHouses,
  fetchTopDestination,
  goToHouse,
  houses,
  keepLastSearchQuery,
  resetDestinationsAndHouses,
  setData,
} = useSearchInput()

const openSearchDropdown = ref(false)

const inputSearch = computed({
  get() {
    return data.value.searchQuery
  },
  set(value) {
    setData('searchQuery', value)
  },
})

const placeholder = computed(() => {
  if (data.value?.location?.name) return ''
  return openSearchDropdown.value
    ? t('search.placeholder')
    : t('search.destination')
})

const onInput = async () => {
  openSearchDropdown.value = true
  await fetchDestinationsAndHouses(inputSearch.value)
}

const closeDropdown = () => {
  openSearchDropdown.value = false
}

const clearActiveLocation = () => {
  setData('location', {})
  inputSearch.value = ''
}

const handleAfterClick = () => {
  openSearchDropdown.value = false
  keepLastSearchQuery()

  if (data.value.location?.type === 'house') {
    return goToHouse()
  }

  emits('selected')
}

onMounted(async () => {
  await fetchTopDestination()
})

watch(openSearchDropdown, (value) => {
  if (value) emits('clicked')
  if (destinations.value.length && !data.value.location.id) {
    const firstDestination = destinations.value[0]
    setData('location', {
      id: firstDestination.id,
      name: firstDestination.name,
      type: 'destination',
    })
  }
  inputSearch.value = ''
  resetDestinationsAndHouses()
})

const onEnter = (event: Event) => {
  if (destinations.value.length) {
    const firstDestination = destinations.value[0]
    setData('location', {
      id: firstDestination.id,
      name: firstDestination.name,
      type: 'destination',
    })
  } else if (houses.value.length) {
    const firstHouse = houses.value[0]
    setData('location', {
      id: firstHouse.id,
      name: firstHouse.name,
      slug: firstHouse.slug,
      type: 'house',
    })
  }

  const target = event.target as HTMLInputElement
  target.blur()

  handleAfterClick()
}
</script>

<template>
  <div v-click-outside="closeDropdown" class="relative">
    <input
      v-model="inputSearch"
      :class="[
        'truncate border-gray-200 transition-width duration-200 ease-in-out',
        { 'w-[148px] pr-6': data.location.name },
        { 'placeholder:text-gray-500': !inputSearch },
        { 'w-[209px]': openSearchDropdown && !data.location.name },
        { 'w-[86px]': !openSearchDropdown && !data.location.name },
      ]"
      :placeholder="placeholder"
      autocomplete="off"
      type="text"
      @focus="openSearchDropdown = true"
      @input="onInput"
      @keyup.enter="onEnter"
    />
    <span
      v-if="data.location.name && !data.searchQuery"
      class="pointer-events-none absolute left-0 w-[148px] truncate pr-6 text-left"
    >
      {{ data.location.name }}
    </span>
    <button
      v-if="inputSearch || data.location.name"
      class="absolute inset-y-0 right-0 pt-0.5"
      type="button"
      @click="clearActiveLocation"
    >
      <BaseIcon color="text-gray-700" name="close" :size="1.1" />
    </button>
    <div
      v-show="openSearchDropdown"
      class="absolute left-[-20px] top-[50px] z-modal w-[340px] rounded border border-gray-200 bg-white py-4 text-left shadow-[0_4px_6px_-1px_rgba(0,0,0,0.1)]"
    >
      <SearchInputV2LocationOptions
        :search-query="inputSearch"
        @option-clicked="handleAfterClick"
      />
    </div>
  </div>
</template>
