<script setup lang="ts">
import type { HouseSlug } from 'lc-services/types'

const { searchQuery = '' } = defineProps<{
  searchQuery: string
}>()
const emits = defineEmits<{ 'option-clicked': [] }>()

const { destinations, houses, optionsSearchEmpty, optionsSearch, setData } =
  useSearchInput()

const options = computed(() => {
  if (!searchQuery && !destinations.value.length && !houses.value.length) {
    return optionsSearchEmpty.value
  }

  return optionsSearch.value
})

const handleLocation = (
  id: string,
  name: string,
  type: 'destination' | 'house',
  slug?: HouseSlug,
) => {
  setData('location', {
    id,
    name,
    type,
    slug,
  })

  emits('option-clicked')
}
</script>

<template>
  <template v-if="options.length">
    <div v-for="d in options" :key="d.key" class="mb-2 last:mb-0">
      <div class="mb-2 text-left text-xs font-bold md:px-4">{{ d.title }}</div>
      <ul class="mb-0">
        <li
          v-for="item in d.items"
          :key="item.id"
          class="flex cursor-pointer items-center py-2 text-md hover:bg-gray-200 md:px-4"
          @click="handleLocation(item.id, item.name, d.key, item.slug)"
        >
          <BaseIcon :name="d.icon" class="mr-4 flex-none" />
          <span class="truncate"> {{ item.name }}</span>
        </li>
      </ul>
    </div>
  </template>
  <div v-else class="text-md md:px-4">
    {{ $t('field.placeholder.noResult') }}
  </div>
</template>
